export const review = [
  {
    name: "Subham Kumar",
    image: "https://media.licdn.com/dms/image/v2/D5603AQGUoziFEDFD8Q/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1722856686545?e=2147483647&v=beta&t=DknFveaBBUYmI-UmxXEEH6DC4pCfzuFpuTDQwZssjKI",
    review:
      "Codemap has been a game-changer in my learning journey. The DSA course helped me strengthen my problem-solving skills with clear, step-by-step explanations and hands-on practice. The MERN stack course, on the other hand, made web development so much easier to grasp, covering everything from frontend to backend with real-world projects. The instructors are top-notch, and the platform is engaging and easy to navigate. Whether you're preparing for coding interviews or want to become a full-stack developer, Codemap is the perfect place to start",
    star: "5",
  },
  {
    name: "Shankar Verma",
    image: "https://media.licdn.com/dms/image/D5603AQGS4IeZVvIBMQ/profile-displayphoto-shrink_200_200/0/1720810717149?e=2147483647&v=beta&t=wUiYdLDU3h_rO7ij-6GT9suOl4cgwNRXtltVZlY12ME",
    review:
      "Codemap's DSA course was exactly what I needed to ace my coding interviews. The concepts are broken down in a way that's easy to understand, and the exercises really help you solidify your understanding. The MERN course was equally impressive, taking me from zero to building full-stack applications confidently. The support from the instructors and the community is fantastic!",
    star: "5",
  },
  {
    name: "Shetal Misra",
    image: "https://media.licdn.com/dms/image/v2/D5603AQGo44tvCw1uOQ/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1694061056338?e=2147483647&v=beta&t=FI8_jwlgCazE6B7qQLgKzIq1CwmXDOzrCeJFKLSU63Q",
    review:
      "I signed up for both the DSA and MERN courses at Codemap, and it was the best decision for my career. The DSA course enhanced my algorithmic thinking, while the MERN course gave me practical, project-based experience. The lessons are thorough, and the learning environment is incredibly motivating. I highly recommend Codemap to anyone looking to level up their skills!",
    star: "5",
  },
  {
    name: "Venkatesh Rao",
    image: "https://media.licdn.com/dms/image/C5603AQHYZafjE14IZg/profile-displayphoto-shrink_200_200/0/1625319698069?e=2147483647&v=beta&t=L8Srtd339ZpnJq0davVUuCWwA6hWu87o_6eXL3S_a5A",
    review:
      "Codemap’s MERN stack course is hands down the best resource for aspiring full-stack developers. The lessons are clear and easy to follow, and I loved how each technology—MongoDB, Express, React, and Node.js—was taught in-depth before tying them together in real-world projects. The hands-on approach gave me the skills and confidence to start building my own web applications. I highly recommend Codemap for anyone serious about mastering the MERN stack!",
    star: "5",
  },
];
