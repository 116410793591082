import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import SideBar from "../components/SideBar";
import ShowVideo from "../components/ShowVideo";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { userAuthenticated } from "../utils/util";


const VideoPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [videoContent, setVideoContent] = useState();

  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    !validUser && navigate("/login");
    // eslint-disable-next-line
  }, []);

  const [visibleSidebar, setIsVisisbleSidebar] = useState(true);
  const [activeVideo, setActiveVideo] = useState({
    sectionIndex: 0,
    videoIndex: 0,
  });

  axios.defaults.withCredentials = true;

  useEffect(() => {
    const setCourseContents = async () => {
      let myCoursesData = localStorage.getItem("userCourses");
      try {
        if (
          myCoursesData === undefined ||
          myCoursesData === null ||
          myCoursesData === ""
        ) {
          const fetchMyCourses = async () => {
            let courseContent;
            const courseDetails = await axios.post(
              `${process.env.REACT_APP_BE_HOST}/user/courses`,
              {
                email: localStorage.getItem("userEmail"),
              }
            );
            courseContent = courseDetails.data.appData;
            courseContent.map((m) => {
              let splited = location.pathname.split("/");
              let courseId = splited[splited.length - 1];
              if (m.id === Number(courseId)) {
                setVideoContent(JSON.parse(m.details).content);
              }
              return null
            });
            localStorage.setItem(
              "userCourses",
              JSON.stringify(courseDetails.data.appData)
            );
          };
          fetchMyCourses();
        } else {
          let splited = location.pathname.split("/");
          let courseId = splited[splited.length - 1];
          let courses = JSON.parse(localStorage.getItem("userCourses"));
          courses.map((m) => {
            if (m.id === Number(courseId)) {
              setVideoContent(JSON.parse(m.details).content);
            }
            return null
          });
        }
      } catch (err) {
        setTimeout(() => {
          document.querySelector(".waitMsg").innerHTML =
            "Sorry for the Inconvenience";
        }, 7000);
      }
    };
    setCourseContents();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {videoContent ? (
        <>
          <SideBar
            visibleSidebar={visibleSidebar}
            setIsVisisbleSidebar={setIsVisisbleSidebar}
            videoData={videoContent}
            setActiveVideo={setActiveVideo}
          />
          <div className="mt-16 video-page-container relative flex overflow-auto">
            {/* show content button  */}
            <button
              className="show_course_content absolute top-8 z-20 -left-36 hover:left-0 transition-all delay-200 flex items-center gap-4 w-fit h-fit backdrop-blur-sm bg-white bg-opacity-30 p-4 rounded-r-sm cursor-pointer"
              onClick={() => setIsVisisbleSidebar(true)}
            >
              <span className="font-semibold">Course Content</span>
              <FaArrowRight className="" size={20} />
            </button>

            {/* video container  */}
            <div className="video_container w-full h-fit bg-white">
              <ShowVideo
                visibleSidebar={visibleSidebar}
                videoData={videoContent}
                activeVideo={activeVideo}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mt-16 min-h-screen flex items-center justify-center">
            <h1 className="waitMsg text-xl font-bold">Wait a moment</h1>
          </div>
        </>
      )}
    </>
  );
};

export default VideoPage;
