import React, { useEffect, useState } from "react";
import { IoIosGlobe } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { HiOutlineStar } from "react-icons/hi2";
import { HiStar } from "react-icons/hi2";
import CourseContentBox from "../components/CourseContentBox";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { courseAdd } from "../redux/courseContent/courseContenSlice";

const CourseDetails = () => {
  const [showSection, setShowSection] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const url = location.pathname.split("/");
  const id = url[url.length - 1];
  const courses = useSelector((state) => state.courseQuery.courses);
  const CourseDetails = courses.find((course) => Number(id) === course.id);
  const courseLanguages = CourseDetails.languages;
  const courseSections = JSON.parse(CourseDetails.details).content;


  axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchCourseDetails = async () => {
      const coursePresent = courses.find((course) => course.id === Number(id));
      if (coursePresent === undefined) {
        const response = await axios.post(
          `${process.env.REACT_APP_BE_HOST}/api/course`,
          { email: localStorage.getItem("userEmail") },
          { params: { id: id } }
        );
        dispatch(courseAdd(response.data.appData[0]));
        console.log(response.data.appData[0]);
      }
    };

    fetchCourseDetails();
    // eslint-disable-next-line
  }, []);

  const starCountReturn = () => {
    let currentStar = CourseDetails.rating;
    let remainingStar = 5 - Math.floor(currentStar);
    let ratings = [];
    for (let i = 1; i <= currentStar; i++) {
      ratings = [<HiStar key={i + "filledStar"} />, ...ratings];
    }
    for (let j = 1; j <= remainingStar; j++) {
      ratings = [...ratings, <HiOutlineStar key={j + "blankStar"} />];
    }
    return ratings;
  };

  const toggleSection = (secId) => {
    setShowSection((prevState) => ({
      ...prevState,
      [secId]: !prevState[secId],
    }));
  };

  // const CourseDetails = {
  //   id: "2a957B",
  //   title: "C++ Tutorials",
  //   about: "Introduction to c++ || from beginner to advance",
  //   previousPrice: 1599,
  //   price: 999,
  //   thumbnail:
  //     "https://i.ytimg.com/vi/QCfzudr3LSQ/hqdefault.jpg?sqp=-oaymwEXCOADEI4CSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLDfGgJtDA7OoPqv40DCGwQRqD25jg",
  //   duration: "12hr 30min",
  //   ratings: 3,
  //   languages: ["Hinglish", "English", "Hindi"],
  //   courseCreator: "Chiranjeeb Nayak (Mentor & CEO)",
  //   level: "Beginner",
  //   lectureCount: 45,
  //   totalDuration: "12hr 35min",
  //   contents: [
  //     {
  //       sectionTitle: "About C++",
  //       sectionDuration: "1hr",
  //       sectionTopicCount: 4,
  //       sectionTopics: [
  //         { videoTopic: "Discoverd by whom", videoDuraion: "15min" },
  //         { videoTopic: "Evolution of c++", videoDuraion: "15min" },
  //         { videoTopic: "Why you should learn", videoDuraion: "15min" },
  //         { videoTopic: "Pros and cons", videoDuraion: "15min" },
  //       ],
  //     },
  //     {
  //       sectionTitle: "About C++",
  //       sectionDuration: "1hr",
  //       sectionTopicCount: 4,
  //       sectionTopics: [
  //         { videoTopic: "Discoverd by whom", videoDuraion: "15min" },
  //         { videoTopic: "Evolution of c++", videoDuraion: "15min" },
  //         { videoTopic: "Why you should learn", videoDuraion: "15min" },
  //         { videoTopic: "Pros and cons", videoDuraion: "15min" },
  //       ],
  //     },
  //     {
  //       sectionTitle: "About C++",
  //       sectionDuration: "1hr",
  //       sectionTopicCount: 4,
  //       sectionTopics: [
  //         { videoTopic: "Discoverd by whom", videoDuraion: "15min" },
  //         { videoTopic: "Evolution of c++", videoDuraion: "15min" },
  //         { videoTopic: "Why you should learn", videoDuraion: "15min" },
  //         { videoTopic: "Pros and cons", videoDuraion: "15min" },
  //       ],
  //     },
  //     {
  //       sectionTitle: "About C++",
  //       sectionDuration: "1hr",
  //       sectionTopicCount: 4,
  //       sectionTopics: [
  //         { videoTopic: "Discoverd by whom", videoDuraion: "15min" },
  //         { videoTopic: "Evolution of c++", videoDuraion: "15min" },
  //         { videoTopic: "Why you should learn", videoDuraion: "15min" },
  //         { videoTopic: "Pros and cons", videoDuraion: "15min" },
  //       ],
  //     },
  //     {
  //       sectionTitle: "About C++",
  //       sectionDuration: "1hr",
  //       sectionTopicCount: 4,
  //       sectionTopics: [
  //         { videoTopic: "Discoverd by whom", videoDuraion: "15min" },
  //         { videoTopic: "Evolution of c++", videoDuraion: "15min" },
  //         { videoTopic: "Why you should learn", videoDuraion: "15min" },
  //         { videoTopic: "Pros and cons", videoDuraion: "15min" },
  //       ],
  //     },
  //   ],
  // };
  
  // const courseLanguages = CourseDetails.languages;
  // const courseSections = CourseDetails.contents;

  return (
    <>
      {/* main container  */}
      <div className="grid lg:block mt-16 w-full min-h-screen p-4 mx-auto lg:pl-32 py-12 relative">
        <CourseContentBox
          CourseDetails={CourseDetails}
          starCountReturn={starCountReturn}
          courseLanguages={courseLanguages}
        />

        <div className="w-[90%] md:w-4/5 lg:w-[50vw] place-self-center">
          {/* upper conatiner with course details  */}
          <div className="hidden lg:block h-fit p-4">
            <div className="flex justify-between">
              <div>
                {/* cousrsetitle  */}
                <h1 className="text-3xl font-semibold my-3">
                  {CourseDetails.title}
                </h1>
                {/* course about  */}
                <h3 className="text-lg font-medium my-3">
                  {CourseDetails.about}
                </h3>
              </div>

              {/* course thumbnail div  */}
              <div className="w-[25%] object-cover overflow-hidden rounded">
                <img
                  className="group"
                  src={CourseDetails.thumbnail}
                  alt="course-thumbnail"
                />
              </div>
            </div>

            <div>
              <div className="flex gap-1 justify-end text-2xl my-1">
                {/* ratings of course  */}
                <span className="font-semibold text-lg">
                  {Math.floor(CourseDetails.rating)}{".0  "}
                </span>
                {/* starts function  */}
                <span className="flex text-green-500">{starCountReturn()}</span>
              </div>
              <div className="flex items-center justify-between">
                {/* creator of course  */}
                <span className="text-xs">
                  Created by{" "}
                  <span className="text-blue-500 underline font-medium">
                    {CourseDetails.courseCreator}
                  </span>
                </span>
                {/* languages the course available in  */}
                <span className="flex items-center">
                  <IoIosGlobe className="m-2" />{" "}
                  <span className="flex gap-1 text-sm">
                    <span>{courseLanguages}</span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          {/* lower div with course contents  */}
          <div className="w-full lg:max-w-[60vw] my-8">
            {/* course contents start  */}
            <h2 className="text-xl font-semibold my-4">Course Content</h2>

            {/* total duration lecture count etc  */}
            <p className="text-sm text-gray-600 font-normal my-2">
              {courseSections.length} sections &bull;{" "}
              {CourseDetails.lectureCount} lectures &bull;{" "}
              {CourseDetails.totalDuration} total length
            </p>

            {/* sections details  */}
            <div className="border border-gray-300">
              {courseSections.map((sec, secIn) => (
                <>
                  <div
                    key={sec + secIn}
                    className="border-b border-gray-300 select-none last:border-none"
                  >
                    <h3
                      key={secIn + "eachSection"}
                      onClick={() => toggleSection(secIn)}
                      className={`flex justify-between p-4 cursor-pointer border-b-gray-300 bg-gray-100 ${
                        !showSection[secIn] ? "border-none" : "border-b"
                      }`}
                    >
                      <span className="flex gap-2 items-center">
                        <IoIosArrowUp
                          size={20}
                          className={`transition-all delay-150 ${
                            !showSection[secIn] ? "rotate-180" : ""
                          }`}
                        />{" "}
                        <span className="text-md font-semibold">
                          {sec.sectionTitle}
                        </span>
                      </span>

                      <span className="text-sm font-normal">
                        {/* <span>{sec.sectionTopicCount} &bull; </span> */}
                        <span>{sec.sectionDuration}</span>
                      </span>
                    </h3>

                    {/* video topics listed  */}
                    <div
                      className={`px-4 py-2 transition delay-200 ${
                        !showSection[secIn] ? "hidden" : ""
                      }`}
                    >
                      {/* each video topics  */}
                      {sec.sectionVideos.map((vid, vidIndex) => (
                        <div
                          key={vidIndex + "eachVideo"}
                          className="flex justify-between my-4 mx-2"
                        >
                          <span className="flex gap-3 text-sm items-center">
                            <MdOutlineOndemandVideo />{" "}
                            <span>{vid.videoTitle}</span>
                          </span>{" "}
                          <span className="text-sm">{vid.videoDuraion}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
