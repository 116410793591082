const mernCourseContent = [
    {
      sectionTitle: "Introduction to HTML & CSS",
      sectionVideos: [
        { videoTitle: "Understanding the basics of web development" },
        { videoTitle: "Introduction to HTML tags and attributes" },
        { videoTitle: "Styling HTML pages with CSS" }
      ]
    },
    {
      sectionTitle: "GIT & Responsive Design",
      sectionVideos: [
        { videoTitle: "Introduction to GIT and version control" },
        { videoTitle: "Setting up a GIT repository and committing changes" },
        { videoTitle: "Creating responsive web pages with media queries" },
        { videoTitle: "Introduction to CSS frameworks like Bootstrap" }
      ]
    },
    {
      sectionTitle: "Advanced CSS Techniques & Best Practices",
      sectionVideos: [
        { videoTitle: "Advanced CSS techniques like flexbox and grid" },
        { videoTitle: "Best practices for HTML and CSS development" },
        { videoTitle: "Overview of JavaScript language and its features" }
      ]
    },
    {
      sectionTitle: "JavaScript & ES6 Essentials (Part 1)",
      sectionVideos: [
        { videoTitle: "Variables, data types, and operators" },
        { videoTitle: "Control flow statements (if-else, for, while, switch)" }
      ]
    },
    {
      sectionTitle: "JavaScript & ES6 Essentials (Part 2)",
      sectionVideos: [
        { videoTitle: "Introduction to ES6 features like let, const, and arrow functions" },
        { videoTitle: "Working with functions, objects, and arrays" },
        { videoTitle: "Variable types, scoping, and error handling" }
      ]
    },
    {
      sectionTitle: "JavaScript DOM Manipulation & React Basics",
      sectionVideos: [
        { videoTitle: "Manipulating the DOM with JavaScript" },
        { videoTitle: "Handling events and user interactions with JavaScript" },
        { videoTitle: "Debugging and troubleshooting JavaScript code" }
      ]
    },
    {
      sectionTitle: "Introduction to React & JSX",
      sectionVideos: [
        { videoTitle: "Introduction to React and its features" },
        { videoTitle: "Setting up a React development environment" },
        { videoTitle: "JSX syntax and its benefits" },
        { videoTitle: "Creating React components" }
      ]
    },
    {
      sectionTitle: "React Components, Props & Conditional Rendering",
      sectionVideos: [
        { videoTitle: "Creating reusable React components" },
        { videoTitle: "Using props to pass data between components" },
        { videoTitle: "Creating conditional rendering and handling events in React" },
        { videoTitle: "Best practices for React component development" }
      ]
    },
    {
      sectionTitle: "React States & Hooks",
      sectionVideos: [
        { videoTitle: "Introduction to React Hooks" },
        { videoTitle: "Understanding state and its importance in React" },
        { videoTitle: "Understanding useState and useEffect Hook" },
        { videoTitle: "Implementing custom hooks in React" }
      ]
    },
    {
      sectionTitle: "React Routing and Types of Routers",
      sectionVideos: [
        { videoTitle: "Understanding React Routing" },
        { videoTitle: "Understanding client-side routing & server-side routing" },
        { videoTitle: "Introduction to different types of React routers" }
      ]
    },
    {
      sectionTitle: "Class-Based Components, React Lifecycle & Context",
      sectionVideos: [
        { videoTitle: "Introduction to class-based components" },
        { videoTitle: "Understanding React Lifecycle and its phases" },
        { videoTitle: "Introduction to React context" },
        { videoTitle: "Use of lazy and suspense" }
      ]
    },
    {
      sectionTitle: "Introduction to Node.js",
      sectionVideos: [
        { videoTitle: "Introduction to Node.js and its features" },
        { videoTitle: "Understanding the basics of web servers and HTTP requests" },
        { videoTitle: "Node Modules - HTTP, File Systems, etc." },
        { videoTitle: "Understanding the Node.js event loop and asynchronous programming" }
      ]
    },
    {
      sectionTitle: "Node.js Advanced Concepts",
      sectionVideos: [
        { videoTitle: "Using callbacks, promises, and async/await in Node.js" },
        { videoTitle: "Handling errors and debugging Node.js applications" },
        { videoTitle: "Implementing security best practices in Node.js" }
      ]
    },
    {
      sectionTitle: "Building RESTful APIs with Node & Express.js",
      sectionVideos: [
        { videoTitle: "Understanding the principles of RESTful APIs" },
        { videoTitle: "Setting up an Express.js development environment" },
        { videoTitle: "Building a simple Express.js server" },
        { videoTitle: "Building CRUD operations with Express.js" },
        { videoTitle: "Implementing middleware in Express.js" },
        { videoTitle: "Best practices for building scalable and maintainable APIs" }
      ]
    },
    {
      sectionTitle: "Introduction to MongoDB & Mongoose",
      sectionVideos: [
        { videoTitle: "Understanding NoSQL databases and MongoDB" },
        { videoTitle: "Setting up a MongoDB development environment" },
        { videoTitle: "Building MongoDB schema and models with Mongoose" },
        { videoTitle: "Using Mongoose to perform CRUD operations in MongoDB" }
      ]
    },
    {
      sectionTitle: "MongoDB Indexing, Aggregation & Security",
      sectionVideos: [
        { videoTitle: "Understanding MongoDB indexing and aggregation" },
        { videoTitle: "Implementing authentication and authorization with MongoDB" },
        { videoTitle: "Best practices for securing MongoDB applications" },
        { videoTitle: "Implementing data migrations and backups in MongoDB" }
      ]
    },
    {
      sectionTitle: "State Management with Redux",
      sectionVideos: [
        { videoTitle: "Introduction to state management with Redux" },
        { videoTitle: "Setting up Redux in a React application" },
        { videoTitle: "Creating Redux actions and reducers" },
        { videoTitle: "Integrating Redux with React Components" }
      ]
    },
    {
      sectionTitle: "RESTful API Development with Express.js",
      sectionVideos: [
        { videoTitle: "Advanced RESTful API concepts" },
        { videoTitle: "Implementing authentication and authorization in Express.js" },
        { videoTitle: "Handling file uploads and other advanced features" },
        { videoTitle: "Testing and documenting RESTful APIs" }
      ]
    },
    {
      sectionTitle: "Major Project - Developing FlavourFleet - Food Delivery App",
      sectionVideos: [
        { videoTitle: "Applying the knowledge gained throughout the course" },
        { videoTitle: "Building FlavourFleet - a Food Delivery App with MERN stack" },
        { videoTitle: "Developing features, integrating APIs, and implementing user authentication" },
        { videoTitle: "Project development, testing, and debugging" }
      ]
    },
    {
      sectionTitle: "Deployment and Hosting",
      sectionVideos: [
        { videoTitle: "Introduction to CI & CD pipelines" },
        { videoTitle: "Deploying React applications using hosting services" },
        { videoTitle: "Deploying Node.js applications with server configurations" }
      ]
    }
  ];

export const mernCourseDetails = {
  title1: "Learn FullStack",
  courseName: "MERN",
  title2: "Development with Projects  in 3 Months!",
  courseContent: mernCourseContent,
  watchHours : "60+ Hrs of learning & 300+ Qs",
  doubtSupport : "Individual Doubt Solving TAs & Community",
  liveProject : "10 + live projects with Deployment",
  courseFor: "MERN",
  students : "The MERN stack equips students with crucial front-end and back-end development skills. By mastering MongoDB, Express.js, React, and Node.js, they can create fully functional web applications. This hands-on experience not only strengthens their portfolios but also enhances their employability in a competitive job market, making them attractive candidates to potential employers.",
  professionals : "The MERN stack enhances development efficiency by utilizing JavaScript across the entire stack, streamlining workflows and reducing complexities. Mastering these technologies allows professionals to build robust applications, stay relevant in a rapidly evolving tech landscape, and transition smoothly to better job opportunities or roles that align with their aspirations and expertise.",
  previousPrice: "8999",
  price:"4999"
}