import React, { useEffect, useState } from "react";
import CourseBox from "../components/CourseBox";
import axios from "axios";
import { getRandomId } from "../utils/util";
import { ToastContainer } from "react-toastify";
import { courses } from "../data/courses";

const AllCourses = () => {
  const [allCourse, setAllCourse] = useState([]);
  const userEmail = localStorage.getItem("userEmail");

  axios.defaults.withCredentials = true;
  useEffect(() => {
    const getAllCourses = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BE_HOST}/api/allcourses`,
          {
            email: userEmail,
            txnId: getRandomId(),
          }
        );
        setAllCourse(response.data.appData);
      } catch (err) {
        setAllCourse(courses);
        console.log(err);
      }
    };
    setAllCourse(courses);
    // getAllCourses();

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mt-16">
        {/* toast container  */}
        <ToastContainer />

        {/* free courses section  */}
        {/* <section className="px-8 py-12 bg-gradient-to-l from-gray-300 to-gray-50 min-h-[80vh]">
          <h1 className="text-center text-3xl text-[--h1-color] font-semibold">
            Free Available Courses
          </h1>
          <div className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-4">
            {courses.map((m, i) => (
              <CourseBox key={i} course={m} type={"free"} />
            ))}
          </div>
        </section> */}

        {/* paid courses section  */}
        <section className="px-8 py-12 bg-gradient-to-l from-gray-300 to-gray-50 min-h-[80vh]">
          <h1 className="text-center text-3xl text-[--h1-color] font-semibold">
            Live Courses
          </h1>
          {allCourse[0] && (
            <div className="grid grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-4">
              {allCourse.map((m, i) => (
                <CourseBox key={i} course={m} type={"premium"} />
              ))}
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default AllCourses;
