export const courses = [
    {
      id: 209353,
      courseCreator: "Chiranjeeb Nayak, Developer and Lead Instructor",
      ratings: 4.8,
      reviewCount: "3342",
      title: "Placement Bootcamp : DSA + FullStack",
      about: "SDE Ready Course with DSA and Development",
      thumbnail:
        "https://ept-data-new.s3.amazonaws.com/690582webdsinsternship.jpg",
      source:
        "https://www.youtube.com/watch?v=qHEY9sq9LQo&list=PLPzlWghXWFfF7OFpmEZoHc96m-D6NBECX&pp=iAQB",
      previousPrice: "9999",
      price: "6999",
      duration: "120",
      lectureCount: "80",
      level:"Beginner"
    },
    {
      id: 34678,
      courseCreator: "Chiranjeeb Nayak, Developer and Lead Instructork",
      ratings: 4.2,
      reviewCount: "575",
      title: "Learn C++ Programming -Beginner to Advance- Deep Dive in C++",
      about: "C++ Programming Course | DSA foundation",
      thumbnail:
        "https://d1jnx9ba8s6j9r.cloudfront.net/imgver.1551437392/img/co_img_1539_1633434090.png",
      source:
        "https://www.youtube.com/watch?v=QCfzudr3LSQ&list=PLPzlWghXWFfG3AuEjdyh9bzK7IMMfKiV5&pp=iAQB",
      previousPrice: "4999",
      price: "2999",
      duration: "40",
      lectureCount: "30",
      level:"Beginner"
    },
    {
      id: 3748937,
      courseCreator: "Chiranjeeb Nayak, Developer and Lead Instructor",
      ratings: 4,
      reviewCount: "364",
      title: "Mastering Data Structures & Algorithms using C and C++",
      about: "Data structure and algorithm with C and C++",
      thumbnail:
        "https://media.geeksforgeeks.org/wp-content/cdn-uploads/20220617191050/The-ultimate-beginners-guide-for-DSA.jpg",
      source:
        "https://www.youtube.com/watch?v=Tp08hODccmI&list=PLPzlWghXWFfE2uXDL0ZFNfdO1kXxiqI-t&pp=iAQB",
      previousPrice: "7999",  
      price: "3999",
      duration: "60",
      lectureCount: "40",
      level:"Beginner"
    },
    {
      id: 2030972,
      courseCreator: "Chiranjeeb Nayak, Developer and Lead Instructor",
      ratings: 4.5,
      reviewCount: "1653",
      title: "Web Development Masterclass - MERN Stack Course",
      about: "Full Stack Development : MERN Stack",
      thumbnail:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqBloQ6jlrkEB2FsY2FBCV5GuJJvKMQrbcaw&s",
      source:
        "https://www.youtube.com/watch?v=HHkatk-P8jM&list=PLPzlWghXWFfEDN5SMCN6vpwmIu_BAJBnk&pp=iAQB",
      previousPrice: "8999",
      price: "4999",
      duration: "80",
      lectureCount: "50",
      level:"Beginner"
    }
  ];