import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-[--background-color]">
        <div className="container mx-auto grid grid-rows-1 gap-4 text-white">
          {/* upper part of the footer  */}
          <div className="upperFooter flex flex-wrap p-6 md:pt-12 md:pb-0 items-center md:items-start gap-8 md:gap-4 md:justify-around">
            {/* codemap logo and tagline  */}
            <div className="min-w-92 md:py-8">
              <label className="font-bold text-3xl">
                C<span className="">0</span>dem<span className="">4</span>p
              </label>
              <p className="mt-4 font-medium">
                The Ultimate Guide To Ace SDE Interviews.
              </p>
            </div>
            <div className="md:hidden bg-gray-400 h-[1px] w-[90%]"></div>

            {/* menu div  */}
            <div className="flex flex-col gap-2 ">
              <h3 className="font-bold mb-2">MENU</h3>
              <Link className=" font-medium" to="/about">
                About Us
              </Link>
              <Link className="font-medium" to="/courses">
                Courses
              </Link>
              <Link className="font-medium" to="/contact">
                Contact
              </Link>
            </div>

            {/* services div  */}
            <div className="flex flex-col gap-2 ">
              <h3 className="font-bold mb-2">SERVICES</h3>
              <Link className=" font-medium" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="font-medium" to="/terms">
                Terms of use
              </Link>
              <Link className="font-medium" to="/refund-policy">
                Refund & Cancellation Policy
              </Link>
            </div>

            {/* line break  */}
            <div className="hidden lg:inline-block bg-gray-400 h-[100%] w-[2px]"></div>

            {/* get in touch div  */}
            <div className="flex flex-col gap-2">
              <h3 className="font-bold mb-2">GET IN TOUCH</h3>
              <a className="font-medium" href="mailto:codemap01@gmail.com">
                Email : codemap01@gmail.com
              </a>
              <div>
                Address : 501, NR Residency, Panathur, Bengaluru, 560103
              </div>
            </div>
          </div>

          {/* lower part of the footer  */}
          <div className="lowerFooter flex items-center justify-center flex-col">
            {/* line break div  */}
            <div className="bg-gray-400 h-[1px] w-[90%]"></div>

            {/* copyright tag  */}
            <div className="flex items-center justify-center p-4">
              <p className="font-semibold text-center">
                Copyrigth &copy; 2024 By Codemap. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
