import React, { useEffect, useState } from "react";
import CourseCard from "./CourseCard";
import axios from "axios";

const MyCourses = () => {
  const [mycoursedata, setmycoursedata] = useState(() => {
    let mycourses = JSON.parse(localStorage.getItem("userCourses"));
    if (mycourses === null || mycourses === undefined) {
      return [];
    } else {
      return mycourses;
    }
  });

  axios.defaults.withCredentials = true;
  useEffect(() => {
    const fetchMyCourses = async () => {
      try {
        if (mycoursedata.length <= 0 || Object.keys(mycoursedata).length <= 0) {
          const coursesData = await axios.post(
            `${process.env.REACT_APP_BE_HOST}/api/user/courses`,
            {
              email: localStorage.getItem("userEmail"),
            }
          );
          setmycoursedata(coursesData.data.appData);
          localStorage.setItem(
            "userCourses",
            JSON.stringify(coursesData.data.appData)
          );
        }
      } catch (error) {
        console.log("error in fetching course", error);
      }
    };
    fetchMyCourses();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="px-12 py-8 flex flex-wrap items-start gap-2 min-h-[70vh]">
        {mycoursedata.length > 0 ? (
          <>
            {mycoursedata.map((val) => (
              <CourseCard
                key={val.id}
                id={val.id}
                about={val.about}
                courseTitle={val.title}
                thumbnail={val.thumbnail}
              />
            ))}
          </>
        ) : (
          <>
            <h1 className="text-xl font-bold text-center mt-8">No Courses</h1>
          </>
        )}
      </div>
    </>
  );
};

export default MyCourses;
