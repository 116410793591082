const dsaCourseContent = [
    {
      sectionTitle: "Introduction to c++",
      sectionVideos: [
        { videoTitle: "About c++" },
        { videoTitle: "History of c++" },
        { videoTitle: "Variables and methods" },
        { videoTitle: "Class and objects" },
      ],
    },
    {
      sectionTitle: "Data structure And Algorithms",
      sectionVideos: [
        { videoTitle: "Basic of dsa" },
        { videoTitle: "Problem solving" },
        { videoTitle: "Best practices" },
        { videoTitle: "OOPS concept" },
      ],
    },
  ];

export const dsaCourseDetails = {
  title1: "Learn",
  courseName: "C++",
  title2: "with Data Structures and Algorithms in 2 Months!",
  courseContent: dsaCourseContent,
  watchHours : "50+ Hrs of learning & 300+ Qs",
  doubtSupport : "Individual Doubt Solving TAs & Community",
  liveProject : "300+ leetcode question solved",
  courseFor : "DSA C++",
  students : "who want to prepare for their placements & internships. C++ DSA is a popular option among students to build fundamental understanding of CS, improve problem solving & prepare for their coding interviews.",
  professionals : "who want to build upon existing skills to enhance their problem solving or make a shift to product based tech companies i.e. looking for career advancements within the tech field",
  previousPrice: "7999",
  price:"3999"
}
