import React, { useEffect } from "react";
import Hero from "../components/Hero";
import Counter from "../components/Counter";
import Feature from "../components/Feature";
import Instructor from "../components/Instructor";
import Placement from "../components/Placement";
import Courses from "../components/Courses";
import Joinus from "../components/Joinus";
import Testimonials from "../components/Testimonials";
import { useNavigate } from "react-router-dom";
import { userAuthenticated } from "../utils/util";
import { courses } from "../data/courses";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const validUser = userAuthenticated(document.cookie);
    !validUser && navigate("/login");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Hero />
      <Counter />
      <Feature />
      <Instructor />
      <Placement />
      <Courses course={courses} />
      <Testimonials />
      <Joinus />
    </>
  );
};

export default Home;
