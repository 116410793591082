import React from "react";
import { Link } from "react-router-dom";
import { MdScreenSearchDesktop } from "react-icons/md";
import SucessImg from "../assets/payment-done.png";

function Sucess() {
  return (
    <>
    <div className="min-h-screen p-8 flex flex-col items-center justify-start">
      <img className="h-[40vh]" src={SucessImg} alt="empty" />
      <h1 className="text-2xl lg:text-4xl 2xl:text-6xl font-medium text-[--h1-color] leading-normal">
        Learn & Become The
      </h1>
      <h1 className="text-2xl lg:text-4xl 2xl:text-6xl font-medium text-[--h1-color] leading-normal">
        <strong>Top 1% Developers</strong>
      </h1>

      {/* para tag  */}
      <p className="text-black mt-4 mb-6 text-xl 2xl:text-2xl font-semibold">
        The Ultimate Guide To Ace SDE Interviews
      </p>
      <Link
        className="text-xl font-medium my-4 flex items-center gap-2 bg-blue-500 rounded px-2 py-2 text-white"
        to="/courses"
      >
        {" "}
        <MdScreenSearchDesktop size={25} /> Explore Courses
      </Link>
    </div>
    </>
  );
}

export default Sucess;
