import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllCourses from "./pages/AllCourses";
import Contact from "./pages/Contact";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import MyLearning from "./pages/MyLearning";
import Profile from "./pages/Profile";
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import SupportPage from "./pages/SupportPage";
import VideoPage from "./pages/VideoPage";
import NotFound from "./pages/NotFound";
import ForgotPass from "./pages/ForgotPass";
import Layout from "./components/Layout";
import CourseContents from "./pages/CourseContents";
import Cart from "./pages/Cart";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Refund from "./pages/Refund";
import HighlightedCourse from "./pages/HighlightedCourse";
import Terms from "./pages/Terms";
import Sucess from "./pages/Sucess";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout><Landing /></Layout>} />
        <Route path="/home" element={<Layout><Home /></Layout>} />
        <Route path="/mylearnings" element={<Layout><MyLearning /></Layout>} />
        <Route path="/courses" element={<Layout><AllCourses /></Layout>} />
        <Route path="/course/details/:id" element={<Layout><CourseContents /></Layout>} />
        <Route path="/course/:course-name" element={<Layout><HighlightedCourse /></Layout>} />
        <Route path="/cart" element={<Layout><Cart /></Layout>} />
        <Route path="/contact" element={<Layout><Contact /></Layout>} />
        <Route path="/support" element={<Layout><SupportPage /></Layout>} />
        <Route path="/profile" element={<Layout><Profile /></Layout>} />
        <Route path="/course/video/:id" element={<Layout><VideoPage /></Layout>} />
        <Route path="/forgotpassword" element={<Layout><ForgotPass /></Layout>} />
        <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />
        <Route path="/refund-policy" element={<Layout><Refund /></Layout>} />
        <Route path="/terms" element={<Layout><Terms /></Layout>} />
        <Route path="/sucess" element={<Layout><Sucess/></Layout>} />
        <Route path="/login" Component={Login} />
        <Route path="/signup" Component={Signup} />
        <Route path="*" Component={NotFound} />
      </Routes>
    </BrowserRouter>

  );
}

export default App;
